body {
  margin: 0;
  font-family: 'Source Sans 3', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(17,17,17);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-35{
  width:auto;
}
a.nav-link{
  color:white !important;
  text-decoration: none;
}
.w-adjust{
  width:75%;
}
.no-decor{
  text-decoration: none !important;
  color: inherit !important;
}
.min-height-100{
  height:60vh;
}
 

.w_90{
  width:90%;
}
.w_10{
  width:10%;
}

/* Hide default scrollbar */
#pagecontent::-webkit-scrollbar {
  display: none;
}

.terms {
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/black_cover.svg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: 15%;
}

@media screen and (min-width:992px){
  .w-35{
    width:35%;
  }
  .w-adjust{
    width:100%;
  }
}

@media screen and (min-height:1050px){
  .min-height-100{
    height:70vh
  }
}
@media screen and (min-height:1500px){
  .min-height-100{
    height:75vh
  }
}
@media screen and (min-height:1800px){
  .min-height-100{
    height:80vh
  }
}